.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
  width: 30px !important;
  height: 30px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye svg {
  width: 30px !important;
  height: 30px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  width: 30px !important;
  height: 30px !important;

  svg {
    width: 30px !important;
    height: 30px !important
  }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  left: 45% !important
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0 !important
}

// .ant-picker-dropdown {
//   @media (max-width: 768px) {
//     inset: 40.9333px auto auto 0px !important;
//   }
// }

// .ant-picker-dropdown .ant-picker-panel-container {
//   @media (max-width: 768px) {
//     flex-direction: column !important;
//     max-width: 100vw !important;
//     // overflow: auto !important;
//     inset: 40.9333px auto auto 0px !important;
//   }
// }

.ant-picker-panel-container {
  .ant-picker-presets {
      min-height: 25rem !important;
  }
  @media (max-width: 768px) {
      // overflow: scroll !important;
      // height: 600px;
      .ant-picker-panel-layout {
          flex-direction: column !important;

          .ant-picker-presets {
              max-width: 100% !important;
              min-height: 10rem !important;
          }

          .ant-picker-panels,
          .ant-picker-datetime-panel {
              flex-direction: column !important;
          }
      }
  }
}

@media (max-width: 768px) {
  .ant-picker-panel {
    width: 100%;
    .ant-picker-datetime-panel {
      flex-direction: column !important;
  }
  }

}